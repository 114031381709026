import React from 'react';
import { graphql } from 'gatsby';
import { Box, Link, Typography } from '@material-ui/core';
import useStyles from '../pagesStyles/404Styles';
import Layout from '../components/layout';
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const Error404Page = ({data}) => {

    const classes = useStyles();

    const { frontmatter, html } = data.markdownRemark;

    return (
      <Layout>
      {/* SEO (do not use nofollow if you do not want it to be applied to all pages) */}
      <GatsbySeo noindex={true} />
      {/* <meta name="robots" content="noindex,follow">
      <meta name="googlebot" content="noindex,follow"> */}

        <Box className={classes.container}>
          <Box className={classes.textContainer}>
            <Typography paragraph className={classes.title} component='h1'>
                {frontmatter.title}
            </Typography>
            <Typography paragraph className={classes.subtitle} component='h2'>
                {frontmatter.subtitle}
            </Typography>
            <Typography paragraph className={classes.text} component='p'>
                {frontmatter.phrase1}
            </Typography>
            <Typography paragraph className={classes.text} component='p'>
                {frontmatter.phrase2}<Link to={frontmatter.link} className={classes.link}>{frontmatter.back}</Link>
            </Typography>
          </Box>   
          <Box className={classes.pictureContainer} component='div'>
            <Box className={classes.image} dangerouslySetInnerHTML={{ __html: html}} />            
          </Box>
        </Box>
      </Layout>
    )
}

export default Error404Page

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/404.md/"}) {
        html
        frontmatter {
            title
            subtitle
            phrase1
            phrase2
            back
            link
            alt
        }
    }
  }
`