import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
title: {
  fontWeight: 700,
  fontSize: 60,
  '@media (max-width:1000px)': {
    fontSize: 40,
    textAlign: 'center',
  },
},
subtitle: {
  fontWeight: 700,
  fontSize: 50,
  '@media (max-width:1000px)': {
    fontSize: 30,
    textAlign: 'center',
  },
},
text: {
 fontWeight: 700,
 fontSize: 30,
 '@media (max-width:1000px)': {
  textAlign: 'center',
  fontSize: 16
 },
},
link: {
  color: '#000',
  textDecoration: 'underline'
},
container: {
  display: 'flex',
  alignItems: 'center',
  padding: '10%',
  backgroundColor: '#fff',
  '@media (max-width:1000px)': {
    flexDirection: 'column-reverse',
    width: '100%'
  },
},
textContainer: {
  width: '50%',
  lineHeight: '3rem',
  fontWeight: 700
},
pictureContainer: {
  width: '50%'
},
image: {
  width: 432,
  height: 568,
  '@media (max-width:1000px)': {
    width: 215,
    height: 290,
  },
}
}));